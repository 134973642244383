import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        // Azure ADで取得したクライアントID(NTT東環境)
        clientId: "e2b5a140-96dc-4fc1-bd16-4beba167e6d0",
        // テナントID(NTT東環境)
        authority: "https://login.microsoftonline.com/2db4d89b-b8a7-4d6c-9611-812748f24c80",
        // リダイレクトURI(NTT東環境、認証完了後に遷移するページURL)
        redirectUri: "https://www.tokyo-generative-ai.ntt-east.jp", 
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;